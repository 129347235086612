import React from 'react';

import cloud_img from '../../asset/image/cloud_img.jpg'
import cloud_icon1 from '../../asset/image/cloud_icon1.jpg'
import cloud_icon2 from '../../asset/image/cloud_icon2.jpg'
import cloud_icon3 from '../../asset/image/cloud_icon3.jpg'
import cloud_icon4 from '../../asset/image/cloud_icon4.jpg'
import cloud_icon5 from '../../asset/image/cloud_icon5.jpg'
import cloud_icon6 from '../../asset/image/cloud_icon6.jpg'
import cloud_icon7 from '../../asset/image/cloud_icon7.jpg'
import cloud_icon8 from '../../asset/image/cloud_icon8.jpg'
import {Link} from 'react-router-dom'

const Cloud = () => {

    const hasWindow = (typeof window !== 'undefined') ? true : false;

    if(hasWindow){
        window.scrollTo(0, 0);
    }


    return (
        <section className="sub_container">
            <div className="navi clfx">
                <ul className="clfx">
                    <li>Home</li>
                    <li>서비스</li>
                    <li>ENEY 클라우드</li>
                </ul>
            </div>

            <div className="sub_title_area clfx">
                <div className="img_area"><img src={cloud_img} alt=""/></div>
                <div className="txt_area">
                    <h1 className="title_style_2">ENEY 클라우드</h1>
                    <p className="txt_style_2">
                        에네이 클라우드는 보안 및 개발 전문가가 없는 기업을<br/>
                        위한 클라우드 보안 솔루션입니다.
                    </p>
                    <div className="btn_area">
                        <Link to="/price" className="basic-btn01 btn-sky-bg">서비스 이용하기</Link>
                    </div>
                </div>
            </div>

            <div className="use_method">
                <h2 className="title_style_3">활용법</h2>
                <ul className="list_style_1">
                    <li>
                        <h3>1. 현재 고객의 구성에서 쉽게 적용이 가능함</h3>
                        <p>DNS의 IP변경만으로 에네이 보안존 필터를 이용할 수 있습니다.</p>
                    </li>
                    <li>
                        <h3>2. 범용클라우드 보다는 합리적인 비용으로 이용가능</h3>
                        <p>
                            보안 전문가가 없어도, 합리적인 비용으로 운영 및 관리가 가능합니다.
                        </p>
                    </li>
                    <li>
                        <h3>3. 백업서비스로 문제 발생 시 백업가능</h3>
                        <p>오픈스택기반의 클라우드 컴퓨팅 및 백업 기술로 다양한 보안 이슈로 부터 데이터를 지킬 수 있습니다. </p>
                    </li>
                    <li>
                        <h3>4. 쉽고 간편한 웹기반 콘솔제공</h3>
                        <p>로그조회, 서비스상태체크, 상태정검 및 이슈발생 시 분석을 제공합니다.  </p>
                    </li>
                </ul>
            </div>

            <div className="function_list">
                <h2 className="title_style_3">주요기능</h2>
                <div className="tb_style_1">
                    <table>
                        <colgroup>
                            <col style={{width:"16%"}}/>
                            <col style={{width:"16%"}}/>
                            <col style={{width:"16%"}}/>
                            <col style={{width:"16%"}}/>
                            <col style={{width:"16%"}}/>
                            <col style={{width:"16%"}}/>

                        </colgroup>
                        <thead>
                        <tr>
                            <th colSpan={2} scope="col" className="blue_bg">구분</th>
                            <th scope="col">STANDARD</th>
                            <th scope="col">PREMIUM</th>
                            <th scope="col">PREMIUM PLUS</th>
                            <th scope="col">ENTERPRISE(연간)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td rowSpan={4}><span>Network</span></td>
                            <td>퍼블릭IP</td>
                            <td>●</td>
                            <td>●</td>
                            <td>●</td>
                            <td>●</td>
                        </tr>
                        <tr>
                            <td>IPsec VPN</td>
                            <td>●</td>
                            <td>●</td>
                            <td>●</td>
                            <td>●</td>
                        </tr>
                        <tr>
                            <td>SSL-VPN</td>
                            <td>●</td>
                            <td>●</td>
                            <td>●</td>
                            <td>●</td>
                        </tr>
                        <tr>
                            <td>IP, 포트 기반 정책설정</td>
                            <td>●</td>
                            <td>●</td>
                            <td>●</td>
                            <td>●</td>
                        </tr>
                        <tr>
                            <td rowSpan={3}>방화벽</td>
                            <td>IPS</td>
                            <td>X</td>
                            <td>●</td>
                            <td>●</td>
                            <td>●</td>
                        </tr>
                        <tr>
                            <td>웹공격 탐지(기본)</td>
                            <td>X</td>
                            <td>●</td>
                            <td>●</td>
                            <td>●</td>
                        </tr>
                        <tr>
                        <td>트래픽 Logging</td>
                            <td>X</td>
                            <td>●</td>
                            <td>●</td>
                            <td>●</td>
                        </tr>
                        <tr>
                            <td>웹방화벽</td>
                            <td>웹공격 탐지(Export)</td>
                            <td>X</td>
                            <td>●</td>
                            <td>●</td>
                            <td>●</td>
                        </tr>
                        <tr>
                            <td>백업</td>
                            <td>인스턴스백업(하루에 한번)</td>
                            <td>X</td>
                            <td>X</td>
                            <td>●</td>
                            <td>●</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>설치비(1회성)</td>
                            <td>3만원</td>
                            <td>7만원</td>
                            <td>7만원</td>
                            <td>별도협의</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>기본료</td>
                            <td>7만원/월</td>
                            <td>20만원/월</td>
                            <td>25만원/월</td>
                            <td>별도협의</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>트래픽(1Mbps)</td>
                            <td>0.5만원/월</td>
                            <td>1만원/월</td>
                            <td>1만원/월</td>
                            <td>1만원/월</td>
                        </tr>
                        </tbody>
                    </table>
                    <span>※ 설치비 및 Network 기본료를 제외하고 트래픽 형태로 과금 됩니다(1Mbps당) </span>

                </div>

                <ul className="list_style_2 clfx">
                    <li>
                        <div className="icon"><img src={cloud_icon1} alt=""/></div>
                        <div className="txt_area">
                            <h3>이미지</h3>
                            <p>
                                서비스 운영에 영향 없이 설정한 자동 백업 일정에 맞게 신속하게 <br/>
                                서버를 백업하고 장애 발생 시 원하는 시점으로 클라우드 서버를 <br/>
                                OS까지 복원할 수 있는 서비스
                            </p>
                            <div className="btn_area">
                                <Link to="/customerservice?type=help&page=1&id=393">→ 자세히 알아보기</Link>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="icon"><img src={cloud_icon2} alt=""/></div>
                        <div className="txt_area">
                            <h3>스냅샷</h3>
                            <p>
                                운영 중인 클라우드 서버의 특정 시점을 사진을 찍듯이 찍어서 보관하는 것을 <br/>
                                말합니다. 생성한 스냅샷으로 복구를 하시면 클라우드 서버는 스냅샷의 시점 <br/>
                                으로 복원됩니다.
                            </p>
                            <div className="btn_area">
                                <Link to="/customerservice?type=help&page=1&id=394">→ 자세히 알아보기</Link>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="icon"><img src={cloud_icon3} alt=""/></div>
                        <div className="txt_area">
                            <h3>웹방화벽</h3>
                            <p>
                                HTTP/HTTPS 프로토콜에 대한 트래픽을 감시하여 공격을 탐지하고 <br/>
                                해당 공격이 웹 서버에 도달하기 전에 차단하는 보안 솔루션 입니다.
                            </p>
                            <div className="btn_area">
                                <Link to="/customerservice?type=help&page=1&id=399">→ 자세히 알아보기</Link>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="icon"><img src={cloud_icon4} alt=""/></div>
                        <div className="txt_area">
                            <h3>공인고정IP</h3>
                            <p>
                                고정 IP는 할당 받은 IP 주소를 그 사람이 독점으로 사용 할 수있는 IP를 <br/>
                                제공합니다.
                            </p>
                            <div className="btn_area">
                                <Link to="/customerservice?type=help&page=1&id=341">→ 자세히 알아보기</Link>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="icon"><img src={cloud_icon5} alt=""/></div>
                        <div className="txt_area">
                            <h3>VPN서비스</h3>
                            <p>
                                전화 부재중 또는 통화종료 후 발신자에게 이벤트, 홍보관련 메시지 전송 <br/>
                                SMS / LMS / MMS 모두 확인 가능
                            </p>
                            <div className="btn_area">
                                <Link to="/customerservice?type=help&page=1&id=399">→ 자세히 알아보기</Link>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div className="icon"><img src={cloud_icon6} alt=""/></div>
                        <div className="txt_area">
                            <h3>SSL VPN</h3>
                            <p>
                                서버 또는 내부 네트워크 접속시 SSL VPN 인증을 통해  허가 가능자만 <br/>
                                접속이 가능하도록 하는 서비스
                            </p>
                            <div className="btn_area">
                                <Link to="/customerservice?type=help&page=1&id=392">→ 자세히 알아보기</Link>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div className="icon"><img src={cloud_icon7} alt=""/></div>
                        <div className="txt_area">
                            <h3>NMS</h3>
                            <p>
                                네트워크 메니지먼트 시스템 중 PRTG NETWORK MONITOR 솔루션 <br/>
                                에네이 클라우드에서  빠르게 설정하여 제공 합니다.
                            </p>
                            <div className="btn_area">
                                <Link to="/customerservice?type=help&page=1&id=338">→ 자세히 알아보기</Link>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div className="icon"><img src={cloud_icon8} alt=""/></div>
                        <div className="txt_area">
                            <h3>ssl인증서</h3>
                            <p>
                                고정 IP는 할당 받은 IP 주소를 그 사람이 독점으로 사용 할 수있는 IP를 <br/>
                                제공합니다.
                            </p>
                            <div className="btn_area">
                                <Link to="/customerservice?type=help&page=1&id=340">→ 자세히 알아보기</Link>
                            </div>
                        </div>
                    </li>


                </ul>
            </div>
        </section>
    );
};

export default Cloud;